import React from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { v4 as uuidv4 } from "uuid";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Editor } from "@tinymce/tinymce-react";
import "./App.css";
import { Peer } from "peerjs";
import { FileUploader } from "react-drag-drop-files";
// import logo from 'zipfile.png'

let key;
let peerjs;
let ownKey;
const init = async () => {
  key = await AsyncStorage.getItem("key");
  if (!key) {
    key = uuidv4().split("-")[0];
    await AsyncStorage.setItem("key", key);
  } else {
  }
  peerjs = new Peer(key, {
    expire_timeout: 999999999,
    alive_timeout: 9999999999999999999n,
  });
  console.log(peerjs);
  if (peerjs._disconnected || peerjs._destroyed) {
    console.log(peerjs._id);
  }
};

init();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      key: "",
      peerjs: {},
      conn: {},
      peers: [],
      peer: "",
      peer2: "",
      data: "",
      message: "",
      start: true,
      files: "",
      file: "",
      open: false,
      line: null,
      display: false,
    };
  }

  peerInit2 = () => {};

  peerInit = () => {};

  async componentDidMount() {
    window.addEventListener("offline", function (e) {
      //  console.log('off');
      this.setState({ line: "Offline" });
    });
    window.addEventListener("online", function (e) {
      // console.log('on');
      this.setState({ line: "Online" });
    });

    this.setState({ key });
    this.setState({ peerjs }, () => {
      this.state.peerjs.on("connection", (conn) => {
        conn.on("data", (data) => {
          // console.log(data);
          if (typeof data == "object") {
            const url = URL.createObjectURL(data); // Create a URL for the Blob
            const link = document.createElement("a");
            link.href = url;
            link.download = "received-file.zip"; // Set the download filename
            link.click();
            URL.revokeObjectURL(url);
          } else {
            this.setState({ data });
          }
        });
        conn.on("open", () => {
          // conn.send("hello!");
        });
      });
    });
    if (peerjs._id) {
      this.setState({ start: !this.state.start, open: false });
    }

    let peers = await AsyncStorage.getItem("peers");
    peers = JSON.parse(peers);
    // console.log(peers);
    if (peers === null) {
      await AsyncStorage.setItem("peers", JSON.stringify([]));
    } else {
      this.setState({ peers });
    }

    //   if(this.state.peerjs._id){
    //     this.state.peerjs.on("connection", (conn) => {
    //     this.setState({conn})
    //     conn.on("data", (data) => {
    //       console.log(data);
    //     });
    //     conn.on("open", () => {
    //       conn.send("hello!");
    //     });
    //   });
    //  }
    setInterval(() => {
      let { peerjs, peer2 } = this.state;
      if (peerjs._destroyed) {
        peerjs = new Peer(key);
        // console.log(this.state.peer2, peerjs._disconnected);
        if (this.state.peer2 && peerjs._disconnected === false) {
          const timer = setTimeout(() => {
            // console.log("connected");
            const conn = peerjs.connect(peer2);
          }, 2000);
        }
      } else if (peerjs._disconnected && !peerjs._destroyed) {
        // console.log(peerjs._disconnected);
        peerjs.reconnect();
        // console.log(this.state.peer2, peerjs._disconnected);
        if (this.state.peer2 && peerjs._disconnected === false) {
          const timer = setTimeout(() => {
            // console.log("connected");
            const conn = peerjs.connect(peer2);
          }, 2000);
        }
      }
    }, 4000);

    const _this = this;
    peerjs.on("connection", async function (data) {
      NotificationManager.success("", `Connected to ${data.peer}`);
      const find = _this.state.peers.find((seen) => {
        return seen === data.peer;
      });

      if (!find) {
        if (find == _this.state.key) {
          return false;
        }

        _this.state.peers.push(data.peer);
        _this.setState({ peers: _this.state.peers });
        await AsyncStorage.setItem("peers", JSON.stringify(_this.state.peers));
      }
    });

    if (peerjs) {
      peerjs.on("disconnected", function () {
        return NotificationManager.error(
          "",
          `You are disconnected from the internet`
        );
      });

      peerjs.on("close", function () {
        return NotificationManager.error(
          "",
          `You are disconnected from the internet`
        );
      });
    }
    const isEmpty = Object.keys(this.state.conn).length === 0;
    if (!isEmpty) {
      this.state.conn.on("disconnected", function () {
        this.setState({ peer2: "" });
        return NotificationManager.error(
          "",
          `${this.state.peer2} is disconnected.`
        );
      });

      this.state.conn.on("close", function () {
        this.setState({ peer2: "" });
        return NotificationManager.error(
          "",
          `${this.state.peer2} is disconnected.`
        );
      });
    }
  }
  addPeerhh = async () => {
    peerjs.destroy();
    console.log(peerjs);
    setTimeout(() => {
      console.log(peerjs);
    }, 4000);
  };
  addPeerhhh = async () => {
    // peerjs.reconnect();
    init();
    console.log(peerjs);
    peerjs.disconnect();

    setTimeout(() => {
      peerjs.reconnect();

      console.log(peerjs);
    }, 4000);
  };
  addPeer = async () => {
    let { peers, peer, peerjs } = this.state;

    setTimeout(() => {
      peerjs.connect();
      console.log(peerjs);
    }, 4000);
    console.log(peerjs);

    if (peerjs._id) {
      if (peer.length < 8) {
        return NotificationManager.error("", `Enter at least 8 characters`);
      }
      const find = peers.find((seen) => {
        return seen === peer;
      });
      if (find) {
        return NotificationManager.info("", `${peer} is already added`);
      }

      peers.push(peer);
      this.setState({ peers });
      await AsyncStorage.setItem("peers", JSON.stringify(peers));
      const conn = peerjs.connect(peer);
      this.setState({ conn });
      conn.on("open", () => {
        this.setState({ peer2: peer });
        NotificationManager.success("", `Connected to ${peer}`);
        // console.log(conn);
      });
    } else {
      init();
      console.log("errer");
      NotificationManager.warning(
        "",
        "Make sure you are connected to internet or refresh"
      );
    }
  };

  selectedPeer = (peer) => {
    let { peerjs } = this.state;
    if (peerjs._id) {
      const conn = peerjs.connect(peer);
      this.setState({ conn });
      // console.log(conn);
      conn.on("open", () => {
        this.setState({ peer2: peer });
        NotificationManager.success("", `Connected to ${peer}`);
      });
    } else {
      NotificationManager.warning(
        "",
        "Make sure you are connected to internet or refresh"
      );
    }
  };

  handleChange = (file) => {
    // console.log(URL.revokeObjectURL(file));
  };

  handleClickToOpen = () => {
    this.setState({ open: true });
  };

  handleToClose = () => {
    this.setState({ open: false });
  };

  handleFiles = async (e, drop) => {
    // console.log(e.target.files[0]);
    const { conn, message } = this.state;

    const fileToDataUri = (image) => {
      return new Promise((res) => {
        const reader = new FileReader();
        const { type, name, size } = image;
        reader.addEventListener("load", () => {
          res({
            base64: reader.result.split("base64,")[1],
            name: name,
            type,
            size: size,
          });
        });
        reader.readAsDataURL(image);
      });
    };

    const newImagesPromises = [];
    if (drop == "drop") {
      for (let i = 0; i < e.length; i++) {
        newImagesPromises.push(fileToDataUri(e[i]));
      }
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
    }

    const newImages = await Promise.all(newImagesPromises);
    // console.log(newImages);
    if (conn.peer) {
      this.upload(newImages, conn);
    } else {
      NotificationManager.warning("", "Make sure you are connected to a peer");
    }
  };

  upload = (newImages, conn) => {
    this.setState({ display: true });
    const filesBlob = new Blob(newImages, { type: "application/zip" }); // Assuming you want to compress before sending

    // Send the file blob over the PeerJS connection
    conn.send(filesBlob);

    // Update display state
    this.setState({ display: false });
  };

  render() {
    const handlePeer = (val) => {
      this.setState({ peer: val.target.value });
    };

    const onText = (val) => {
      const { conn, message } = this.state;
      this.setState({ message: val }, () => {
        // console.log(conn);
        if (conn.peer) {
          conn.send(this.state.message);
        } else {
          NotificationManager.warning(
            "",
            "Make sure you are connected to a peer"
          );
        }
      });
    };

    const { key, peers, data, start, open, peer2, line, files } = this.state;

    const copy = (e) => {
      if (e.length >= 1) {
        navigator.clipboard.writeText(e.currentTarget.innerText);
        NotificationManager.success("", "Text copied");
      }
    };

    let timeout;

    const clicked = () => {
      timeout = window.setTimeout(function () {
        window.location.reload(false);
      }, 1300);
    };

    const unclicked = () => {
      clearTimeout(timeout);
    };

    const clicked2 = async (value) => {
      let _this = this;
      timeout = window.setTimeout(function () {
        console.log("efef");
        const peerss = peers.splice(peers.indexOf(value), 1);
        _this.setState({ peers });
        AsyncStorage.setItem("peers", JSON.stringify(peers));
      }, 1300);
    };

    const unclicked2 = () => {
      clearTimeout(timeout);
    };

    return (
      <div className="App">
        <div className="wrp">
          <div>
            {/* <button
              className="input2"
              variant="contained"
              onClick={() => this.addPeerhh()}
            >
              hh
            </button>

            <button
              className="input2"
              variant="contained"
              onClick={() => this.addPeerhhh()}
            >
              hhh
            </button> */}
            <span className="key"> {key} </span>
          </div>
          <div>
            <span className="peer2"> {peer2} </span>
          </div>
          <div>
            <input
              className="input"
              placeholder="Enter ID"
              onChange={(val) => handlePeer(val)}
            />
            <button
              className="input2"
              variant="contained"
              onClick={() => this.addPeer()}
              onMouseDown={() => clicked()}
              onMouseUp={() => unclicked()}
            >
              Connect
            </button>
          </div>
        </div>
        <div>
          <div>
            {line}
            {line === "Online" ? (
              <span className="online"> {line} </span>
            ) : (
              <span className="offline"> {line} </span>
            )}
          </div>
          <div className="listWrapper">
            {peers.map((value, index) => {
              return (
                <li
                  className="list"
                  key={index}
                  onMouseDown={() => clicked2(value)}
                  onMouseUp={() => unclicked2()}
                  onClick={() => this.selectedPeer(value)}
                >
                  {value}
                </li>
              );
            })}
          </div>

          <div className="filess">
            {files ? (
              <a href={files} download="output.zip">
                <div className="zip"></div>
              </a>
            ) : null}
            {/* {files && files.map((file, index) => {
            if(file.type.includes('image')){
                return <a href={file.base64} download><img src={file.base64} key={index} style={{width: '100px', height: '100px'}} download/></a>
            }else{
              return <a href={file.base64} download><iframe src={file.base64} key={index} frameBorder="0" type={file.type} scrolling="hidden" height="100px" width="100px" ></iframe></a>
            }
          })
        } */}
          </div>

          {/* <div >
        <TextField id="outlined-basic" label="Paste code from phone" variant="outlined" onChange={(val)=>handlePeer(val)}/>
          <button  variant="contained" onClick={() => this.addPeer()}>OK</button>
        </div> */}

          <p className="uploader">
            <FileUploader
              handleChange={(e) => this.handleFiles(e, "drop")}
              multiple
              name="file"
              label="Drag and drop/ Click to upload files."
            />
            <div
              class="loader"
              style={
                this.state.display ? { display: "block" } : { display: "none" }
              }
            ></div>
          </p>
          <p>
            <input
              type="file"
              id="files"
              multiple
              onChange={(e) => this.handleFiles(e, "")}
              style={{ display: "none" }}
            />
          </p>
          <div className="text">
            <div className="editor">
              <div className="editor2">
                <Editor
                  apiKey="x2ewecx6316zc3a771rqbez174nmfhmj57vlrs76fp8uyo6g"
                  value={data}
                  init={{
                    selector: "textarea",
                    width: "100%",
                    menubar: false,
                    height: 300,
                    toolbar: "export",
                    content_style: "body { font-size:12px }",
                  }}
                  onClick={(e) => copy(e)}
                />
              </div>
              <div className="editor2">
                <Editor
                  apiKey="x2ewecx6316zc3a771rqbez174nmfhmj57vlrs76fp8uyo6g"
                  init={{
                    selector: "textarea",
                    width: "100%",
                    height: 300,
                    toolbar_mode: "floating",
                    tinycomments_mode: "embedded",
                    content_style:
                      "body { font-size:12px, background-color: black }",
                  }}
                  onEditorChange={(newValue, editor) => {
                    onText(newValue);
                    // console.log(editor.getContent({format: 'text'}));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer style={{ fontSize: 10 }} />
      </div>
    );
  }
}

export default App;
// export default withStyles(styles, { withTheme: true })(App);
